import React, { useState } from 'react'
import Modal from 'react-modal'
import { capitalize } from 'lodash/string'
import { some } from 'lodash/collection'
import { isNull } from 'lodash/lang'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import { useGetCategoriesQuery } from '../../../api/generalApi'
import { 
  usePatchShopsMutation 
} from '../../../api/api.generated'
import { VStack } from '../../../global/styles'
import { globalTheme } from '../../../global/theme'
import {
  WEEK_DAYS,
  DEFAULT_OPEN_TIME,
  DEFAULT_CLOSE_TIME
} from '../../../const/app'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES
} from '../../../const/UIvariants'
import Typography from '../../../components/UI/Typography'
import SelectManyInput from '../../../components/UI/Inputs/SelectManyInput'
import Button from '../../../components/UI/Buttons/Button/Button'
import SwitchInput from '../../../components/UI/Inputs/SwitchInput'
import LoadingSpinner from '../../../components/LoadingSpinner'
import EditWorkingHours from '../../../components/EditWorkingHours'
import ShowErrorModal from '../../../components/ShowErrorModal'
import ShopOperationHoursModal from '../ShopOperationHoursModal'
import {
  Headline,
  ModalBody,
  ShopDetailsHeadlineWrap,
  ShopDetailsHeadline,
  HoursWrapper,
  OpenHoursWrap,
  WorkingDayWrap,
  OperationalHoursErrorWrap,
  ErrorWrap,
  ButtonWrap
} from './styles'

const EditShopTagModal = ({
  isOpen,
  handleCloseModal,
  shopIds
}) => {
  const [error, setError] = useState('')
  const [selectedDay, setSelectedDay] = useState(null)
  const { data: tagsData, isLoading: isLoadingTags, error: tagsError } = useGetCategoriesQuery()
  const [ patchTags, { isLoading: isUpdating } ] = usePatchShopsMutation()

  const validate = values => {
    const errors = {};
    if (values.categories.length === 0) {
      errors.categories = 'Select category';
    }
    if (values.openDays.length === 0) {
      errors.openDays = 'Category should be open at least one day per week';
    }
    return errors;
  }

  const handleSubmit = (values) => {
      /*
      patchTags({ 
        body: { 
          shopIds,
          editCategories: values.editCategories.length > 0,
          categories: values.categories,
          editSchedule: values.editSchedule.length > 0,
          schedule: WEEK_DAYS.reduce(
            (result, DAY) => ({
              ...result,
              [DAY]: {
                isOpen: some(values.openDays, (openDay) => openDay === DAY),
                open: values[`${DAY}Open`],
                close: values[`${DAY}Close`]
              }
            }),
            {}
          )
        } 
      })
      .unwrap()
      .then(() => {
        setError('');
        handleCloseModal()
      })
      .catch((err) => {
        if (err instanceof Error) {
          setError(err.message)
        } else if (err instanceof Object) {
          let message = '';
          Object.keys(err).forEach((key) => {
            message += `${err[key]} `;
          });
          setError(message)
        } else {
          setError('Network error')
        }
      })
      */
  }  

  return (
    <Modal {...{ isOpen }}>
      <ModalBody>
        {isLoadingTags && <LoadingSpinner />}
        { tagsError && 
        <ShowErrorModal
          title="Edit Category Hours"
          error="Network Error"
          onClose={handleCloseModal}
        />
        }  
        {tagsData && 
        <Formik
          initialValues={{        
            categories: [],
            openDays: [],
            mondayOpen: DEFAULT_OPEN_TIME,
            mondayClose: DEFAULT_CLOSE_TIME,
            tuesdayOpen: DEFAULT_OPEN_TIME,
            tuesdayClose: DEFAULT_OPEN_TIME,
            wednesdayOpen: DEFAULT_OPEN_TIME,
            wednesdayClose: DEFAULT_OPEN_TIME,
            thursdayOpen: DEFAULT_OPEN_TIME,
            thursdayClose: DEFAULT_OPEN_TIME,
            fridayOpen: DEFAULT_OPEN_TIME,
            fridayClose: DEFAULT_OPEN_TIME,
            saturdayOpen: DEFAULT_OPEN_TIME,
            saturdayClose: DEFAULT_OPEN_TIME,
            sundayOpen: DEFAULT_OPEN_TIME,
            sundayClose: DEFAULT_OPEN_TIME
          }}
          validate={validate}
          onSubmit={handleSubmit}
        >
          {({ values, errors }) => (
            <Form>
              <Headline>Edit Category Hours</Headline>

              <ShopOperationHoursModal
                isOpen={!isNull(selectedDay)}
                setSelectedDay={setSelectedDay}
                selectedDay={selectedDay}
              />

                <SelectManyInput
                  options={tagsData.map((category) => ({
                    value: category.id,
                    label: category.tagName
                  }))}
                  label="Select Categories"
                  inputName="categories"
                />
                <HoursWrapper>
                  <VStack>
                    <ShopDetailsHeadlineWrap>
                      <ShopDetailsHeadline>Hours Operational</ShopDetailsHeadline>
                    </ShopDetailsHeadlineWrap>
                    <OpenHoursWrap>
                      {WEEK_DAYS.map((dayOfWeek) => (
                        <WorkingDayWrap key={dayOfWeek}>
                          <SwitchInput
                            inputName="openDays"
                            value={dayOfWeek}
                            label={capitalize(dayOfWeek)}
                          />
                          <EditWorkingHours
                            open={values[`${dayOfWeek}Open`]}
                            close={values[`${dayOfWeek}Close`]}
                            day={dayOfWeek}
                            isOpen={some(
                              values.openDays,
                              (dayShopIsOpened) => dayShopIsOpened === dayOfWeek
                            )}
                            setDay={setSelectedDay}
                          />
                        </WorkingDayWrap>
                      ))}
                    </OpenHoursWrap>

                    {errors.openDays && (
                      <OperationalHoursErrorWrap>
                        <Typography
                          variant="textXS"
                          color={globalTheme.colors.skyNeutral1}
                        >
                          {errors.openDays}
                        </Typography>
                      </OperationalHoursErrorWrap>
                    )}
                  </VStack>
                </HoursWrapper>

              {error && 
                <ErrorWrap>
                  <Typography variant="textS" color="red">
                  {error}
                  </Typography>
                </ErrorWrap>          
              }
              <ButtonWrap>
                <Button
                  variant={BUTTON_VARIANTS.PRIMARY}
                  size={BUTTONS_SIZES.MED}
                  type="submit"
                  isLoading={isUpdating}
                >
                  Save
                </Button>
                <Button
                  variant={BUTTON_VARIANTS.SECONDARY}
                  size={BUTTONS_SIZES.MED}
                  onClick={handleCloseModal}
                  type="button"
                >
                  Cancel
                </Button>
              </ButtonWrap>
            </Form>
          )}
        </Formik>
        }
      </ModalBody>
    </Modal>
  )
}

export default EditShopTagModal

EditShopTagModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  shopIds: PropTypes.array.isRequired,
}
