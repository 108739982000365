import React, { useState } from 'react'
import { withTheme } from 'styled-components'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash/lang'
import { Star, ThumbsUpDown } from '@styled-icons/material'
import { filter } from 'lodash/collection'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import {
  BadgeTabsWrap,
  CustomerName,
  CustomerReviewWrap,
  NoReviewsWrap,
  ReviewDetails,
  ReviewWrap,
  ShopAsideReviewWrapper,
  TimeStamp
} from './styles'
import { useGetShopDetailsQuery } from '../../../api/shopApi'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { BADGE_VARIANTS, USER_AVATAR_SIZES } from '../../../const/UIvariants'
import Typography from '../../../components/UI/Typography'
import { globalTheme } from '../../../global/theme'
import UserAvatar from '../../../components/UI/UserAvatar'
import Rating from '../../../components/UI/Rating'
import Badge from '../../../components/UI/Badge'

// todo figure out is that necessary to extend dayjs in every component
dayjs.extend(relativeTime)

const RATES = [1, 2, 3, 4, 5]
const ShopAsideReviews = ({ theme, shopId }) => {
  const [displayedRates, setDisplayedRates] = useState('all')
  const handleSetDisplayedRate = (rateToSet) => {
    setDisplayedRates((prev) => (prev === rateToSet ? 'all' : rateToSet))
  }
  const { data: shopDetails, isLoading } = useGetShopDetailsQuery(shopId)
  return (
    <ShopAsideReviewWrapper>
      {isLoading && <LoadingSpinner />}
      {isEmpty(shopDetails?.customerReviews) ? (
        <NoReviewsWrap>
          <ThumbsUpDown size={64} color={globalTheme.colors.skyNeutral3} />
          <Typography
            variant="textM"
            bold
            color={theme.colors.skyNeutral_2}
            align="center"
          >
            { shopDetails?.shopName } doesn&apos;t have any reviews from visitors yet
          </Typography>
          <Typography
            variant="textS"
            align="center"
            color={theme.colors.skyNeutral1}
          >
            Only visitors can leave a review
          </Typography>
        </NoReviewsWrap>
      ) : (
        <>
          <Typography variant="textM" color={globalTheme.colors.skyNeutral_1}>
            Customer reviews
          </Typography>
          <BadgeTabsWrap>
            {RATES.map((rate) => (
              <Badge
                key={rate}
                variant={BADGE_VARIANTS.GOLD}
                onClick={() => {
                  handleSetDisplayedRate(rate)
                }}
                icon={<Star size={16} color={theme.colors.yellowBase} />}
                isActive={rate === displayedRates}
                label={`${rate}.0`}
              />
            ))}
          </BadgeTabsWrap>
          {filter(
            shopDetails?.customerReviews,
            (review) =>
              displayedRates === review.customerReviews ||
              displayedRates === 'all'
          ).map((review) => (
            <CustomerReviewWrap key={review.reviewID}>
              <UserAvatar
                size={USER_AVATAR_SIZES.SMALL}
                userName={review.customerName}
              />
              <ReviewDetails>
                <CustomerName
                  variant="textXS"
                  color={theme.colors.skyNeutral_2}
                >
                  {review.customerName}
                </CustomerName>
                <ReviewWrap>
                  <Rating rating={review.customerReviews} />
                  <TimeStamp
                    variant="textXS"
                    color={globalTheme.colors.skyNeutral2}
                  >
                    {dayjs(review.timestamp).fromNow(true)}
                  </TimeStamp>
                </ReviewWrap>
              </ReviewDetails>
            </CustomerReviewWrap>
          ))}
        </>
      )}
    </ShopAsideReviewWrapper>
  )
}

export default withTheme(ShopAsideReviews)

ShopAsideReviews.propTypes = {
  shopId: PropTypes.string.isRequired,
  theme: PropTypes.shape({
    colors: PropTypes.object,
    fonts: PropTypes.object
  }).isRequired
}
