import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { isNil } from 'lodash/lang'
import { Add, Close, Loyalty } from '@styled-icons/material'
import { withTheme } from 'styled-components'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import {
  useDeleteShopProductMutation,
  useLazyGetShopProductsQuery
} from '../../../api/shopProductsApi'
import {
  getCategoryById,
  getImageFromServer,
  getErrorMessage
} from '../../../utils/helpers'
import {
  BADGE_VARIANTS,
  BUTTON_VARIANTS,
  BUTTONS_SIZES
} from '../../../const/UIvariants'
import Typography from '../../../components/UI/Typography'
import { useGetCategoriesQuery } from '../../../api/generalApi'
import Button from '../../../components/UI/Buttons/Button'
import AddNewProductModal from '../AddNewProductModal'
import Paginator from '../../../components/Paginator'
import Badge from '../../../components/UI/Badge'
import EditDeleteMenu from '../../../components/EditDeleteMenu'
import {
  AddNewProductBody,
  AddNewProductLabel,
  AddNewProductWrap,
  BadgesWrap,
  Footer,
  ImagePlaceholder,
  LinkTag,
  ProductDetailsWrap,
  ProductImage,
  ProductNameWrap,
  ProductsList,
  ProductWrap,
  TagLinkWrap,
  ErrorMessageWrap
} from './styles'

const ITEMS_PER_PAGE = 50

const ShopProducts = ({ theme, shopId  }) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [totalRows, setTotalRows] = useState(0)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [error, setError] = useState('')
  const [addNewModalIsOpen, setAddNewModalIsOpen] = useState(false)
  const [editModalIsOpen, setEditModalIsOpen] = useState(false)
  const [editProductId, setEditProductId] = useState(null)
  const [getProducts, { data: shopProducts, isLoading }] = useLazyGetShopProductsQuery()
  const { data: shopCategories, isLoading: loadingCategories } = useGetCategoriesQuery(shopId)
  const [deleteShopProduct] = useDeleteShopProductMutation()

  const handleOpenAddProduct = () => {
    setError("")
    setAddNewModalIsOpen(true)
  }

  const handleEditProduct = (productId) => {
    setError("")
    setEditModalIsOpen(true)
    setEditProductId(productId)
  }

  const handleDeleteProduct = (productId) => {
    setError("")
    deleteShopProduct(productId)
      .unwrap()
      .then(() => {
      })
      .catch((err) => {
        setError(getErrorMessage(err))
      })    
  }

  const handleCloseAddProduct = () => {
    setAddNewModalIsOpen(false)
    setEditProductId(null)
  }

  const handleCloseEditProduct = () => {
    setEditModalIsOpen(false)
    setEditProductId(null)
  }

  const loadProducts = () => {
    setError('')
    getProducts({ shopId, page: currentPage, limit: ITEMS_PER_PAGE })
      .unwrap()
      .catch((err) => {
        setError(getErrorMessage(err))
      })
  }
  
  useEffect(() => {
    loadProducts()
  }, [])

  useEffect(() => {
    loadProducts()
  }, [currentPage])

  useEffect(() => {
    if (shopProducts) {
      setTotalPages(shopProducts.pagination.pages)
      setTotalRows(shopProducts.pagination.total)
      if (currentPage >= shopProducts.pagination.pages) {
        setCurrentPage(0)
      }
    }
  }, [shopProducts])

  return (
    <>
      {(isLoading || loadingCategories) && <LoadingSpinner />}
      {error &&
        <ErrorMessageWrap>
          <Typography variant="textS" color="red">{error}</Typography>
        </ErrorMessageWrap>
      }          
      {isNil(shopProducts) && !error && !isLoading && <p>No Products added yet </p>}
      {false && !isNil(selectedCategory) && (
        <BadgesWrap>
          <Typography variant="headingS" color={theme.skyNeutral_2}>
            Displaying for
          </Typography>
          <Badge
            variant={BADGE_VARIANTS.LIGHT}
            icon={<Close size={18} color={theme.colors.skyNeutral1} />}
            key={selectedCategory}
            onClick={() => {
              setSelectedCategory(null)
            }}
            label={getCategoryById({
              categoryId: selectedCategory,
              categories: shopCategories
            })}
          />
        </BadgesWrap>
      )}
      {shopProducts && shopCategories && (
        <ProductsList>
          <AddNewProductWrap key="uniq">
            <ImagePlaceholder>
              <Loyalty size={32} color={theme.colors.green_1} />
            </ImagePlaceholder>
            <AddNewProductBody>
              <AddNewProductLabel variant="textS">
                Add new Products here
              </AddNewProductLabel>
              <Button
                variant={BUTTON_VARIANTS.PRIMARY}
                size={BUTTONS_SIZES.SMALL}
                fillColor={theme.colors.green_1}
                type="button"
                onClick={handleOpenAddProduct}
              >
                <Add size={18} color={theme.colors.white} />
                Add Product
              </Button>
            </AddNewProductBody>
          </AddNewProductWrap>
          {shopProducts.products.map((product) => (
            <ProductWrap key={product.id}>
              <ProductImage src={getImageFromServer(product.productImage)} />
              <ProductDetailsWrap>
                <ProductNameWrap>
                  <Typography variant="textXS" color={theme.colors.skyNeutral1}>
                    {product.productName}
                  </Typography>
                  {(product.organization_product_id == null) &&
                  <EditDeleteMenu
                    onEdit={() => {
                      handleEditProduct(product.id)
                    }}
                    onDelete={() => {
                      handleDeleteProduct(product.id)
                    }}
                  />
                  }
                </ProductNameWrap>
                <Typography variant="textXS" color={theme.colors.skyNeutral_1}>
                  ${product.price}
                </Typography>
                <TagLinkWrap>
                  {product.categories.map((categoryId) => (
                    <LinkTag
                      variant="textXS"
                      key={categoryId}
                      onClick={() => {
                        setSelectedCategory(categoryId)
                      }}
                    >
                      {getCategoryById({
                        categoryId,
                        categories: shopCategories
                      })}
                    </LinkTag>
                  ))}
                </TagLinkWrap>
              </ProductDetailsWrap>
            </ProductWrap>
          ))}
        </ProductsList>
      )}
      {shopProducts && (
        <Footer>
          <Typography variant="textM" color={theme.colors.skyNeutral2}>
            {`${shopProducts.products.length} from ${totalRows} Products`}
          </Typography>
          <Paginator
            amountOfPages={totalPages}
            {...{ currentPage, setCurrentPage }}
          />
        </Footer>
      )}
      {addNewModalIsOpen &&
      <AddNewProductModal
        isOpen={addNewModalIsOpen}
        onClose={handleCloseAddProduct}
        shopId={shopId}
      />
      }
      {editModalIsOpen &&
      <AddNewProductModal
        isOpen={editModalIsOpen}
        onClose={handleCloseEditProduct}
        editProductId={editProductId}
        shopId={shopId}
      />
      }      
    </>
  )
}

export default withTheme(ShopProducts)

ShopProducts.propTypes = {
  theme: PropTypes.object.isRequired,
  shopId: PropTypes.string.isRequired
}
