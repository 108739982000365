import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { isNil } from 'lodash/lang'
import { withTheme } from 'styled-components'
import { Add, ReceiptLong } from '@styled-icons/material'
import {
  useDeleteShopPostMutation,
  useLazyGetShopPostsQuery
} from '../../../api/shopPostsApi'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import { getErrorMessage } from '../../../utils/helpers'
import { BUTTON_VARIANTS, BUTTONS_SIZES } from '../../../const/UIvariants'
import Button from '../../../components/UI/Buttons/Button'
import Typography from '../../../components/UI/Typography'
import Paginator from '../../../components/Paginator'
import AddNewPostModal from '../AddNewPostModal'
import ShopPostItem from '../../../components/ShopPostItem'
import { Footer } from '../../../global/styles'
import {
  AddNewPostBody,
  AddNewPostWrap,
  PostsList,
  ImagePlaceholder,
  ButtonWrap,
  ErrorMessageWrap
} from './styles'

const ITEMS_PER_PAGE = 50

const ShopPosts = ({ theme, shopId }) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [totalRows, setTotalRows] = useState(0)
  const [error, setError] = useState('')
  const [addNewModalIsOpen, setAddNewModalIsOpen] = useState(false)
  const [editModalIsOpen, setEditModalIsOpen] = useState(false)
  const [editPostId, setEditPostId] = useState(null)
  const [getPosts, { data: shopPosts, isLoading, error: loadError }] = useLazyGetShopPostsQuery(shopId)
  const [deleteShopPost] = useDeleteShopPostMutation()

  const handleOpenAddPost = () => {
    setError("")
    setAddNewModalIsOpen(true)
  }

  const handleEditPost = (postId) => {
    setError("")
    setEditModalIsOpen(true)
    setEditPostId(postId)
  }

  const handleDeletePost = (postId) => {
    setError("")
    deleteShopPost(postId)
      .unwrap()
      .then(() => {
      })
      .catch((err) => {
        setError(getErrorMessage(err))
      })    
  }

  const handleCloseAddPost = () => {
    setAddNewModalIsOpen(false)
    setEditPostId(null)
  }

  const handleCloseEditPost = () => {
    setEditModalIsOpen(false)
    setEditPostId(null)
  }

  const loadPosts = () => {
    setError('')
    getPosts({ shopId, page: currentPage, limit: ITEMS_PER_PAGE })
      .unwrap()
      .catch((err) => {
        setError(getErrorMessage(err))
      })
  }

  useEffect(() => {
    loadPosts()
  }, [])

  useEffect(() => {
    loadPosts()
  }, [currentPage])

  useEffect(() => {
    if (shopPosts) {
      setTotalPages(shopPosts.pagination.pages)
      setTotalRows(shopPosts.pagination.total)
      if (currentPage >= shopPosts.pagination.pages) {
        setCurrentPage(0)
      }
    }
  }, [shopPosts])

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {loadError && 
        <ErrorMessageWrap>
          <Typography variant="textS" color="red">Network Error</Typography>
        </ErrorMessageWrap>
      }      
      {error &&
        <ErrorMessageWrap>
          <Typography variant="textS" color="red">{error}</Typography>
        </ErrorMessageWrap>
      }       
      {isNil(shopPosts) && !isLoading && !loadError && <p>No Posts added yet </p>}
      {shopPosts && (
        <PostsList>
          <AddNewPostWrap>
            <ImagePlaceholder>
              <ReceiptLong size={40} color={theme.colors.green_1} />
            </ImagePlaceholder>
            <AddNewPostBody>
              <Typography color={theme.colors.skyNeutral} variant="textXS">
                Add your store posts here, You can add Promos or Announcement
              </Typography>
              <ButtonWrap>
                <Button
                  variant={BUTTON_VARIANTS.PRIMARY}
                  size={BUTTONS_SIZES.SMALL}
                  fillColor={theme.colors.green_1}
                  type="button"
                  onClick={handleOpenAddPost}
                >
                  <Add size={18} color={theme.colors.white} />
                  Add Post
                </Button>
              </ButtonWrap>
            </AddNewPostBody>
          </AddNewPostWrap>
          {shopPosts.posts.map((postItem) => (
            <ShopPostItem
              key={postItem.id}
              postId={postItem.id}
              postImage={postItem.postImage}
              postTitle={postItem.title}
              postDescription={postItem.description}
              postLikeCount={postItem.likeCount}
              postInterestedCount={postItem.interestedCount}
              postAttendedCount={postItem.attendedCount}
              postDeadline={postItem.deadline}
              dateStart={postItem.dateStart}
              dateEnd={postItem.dateEnd}
              deadlineText={postItem.deadlineText}
              showCounts
              showStore={false}
              storeName={postItem.shopName}
              storeImage={postItem.shopImage}
              storeBgColor={postItem.avatarBackground}
              canEdit={!postItem.isOrganizationPost}
              {...{ handleEditPost, handleDeletePost }}
            />
          ))}
        </PostsList>
      )}
      {shopPosts && (
        <Footer>
          <Typography variant="textM" color={theme.colors.skyNeutral2}>
            {`${shopPosts.posts.length} from ${totalRows} Posts`}
          </Typography>
          <Paginator
            amountOfPages={totalPages}
            {...{ currentPage, setCurrentPage }}
          />
        </Footer>
      )}
      {addNewModalIsOpen &&
      <AddNewPostModal
        isOpen={addNewModalIsOpen}
        onClose={handleCloseAddPost}
        shopId={shopId}
      />
      }
      {editModalIsOpen &&
      <AddNewPostModal
        isOpen={editModalIsOpen}
        onClose={handleCloseEditPost}
        editPostId={editPostId}
        shopId={shopId}
      />
      }          
    </>
  )
}

export default withTheme(ShopPosts)

ShopPosts.propTypes = { 
  theme: PropTypes.object.isRequired,
  shopId: PropTypes.string.isRequired
}
