import React, { useEffect, useMemo, useState } from 'react'
import { useGlobalFilter, usePagination, useRowSelect, useTable } from 'react-table'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash/lang'
import { keys } from 'lodash/object'
import { Search, Add,  DeleteOutline } from '@styled-icons/material'
import {
  selectOrganizationId
} from '../../../app/selectors'
import {
  useLazyGetOrganizationsQuery,
  useLazyGetOrganizationQuestionsQuery,
  useDeleteOrganizationQuestionMutation,
  usePostDeleteOrganizationQuestionsMutation,
  usePostOrganizationQuestionsToShopsMutation,
  useDeleteOrganizationQuestionsToShopsMutation  
} from '../../../api/api.generated'
import { INPUT_SIZES, BUTTON_VARIANTS, BUTTONS_SIZES } from '../../../const/UIvariants'
import { getEntityPropertyByIds, getErrorMessage } from '../../../utils/helpers'
import Typography from '../../../components/UI/Typography'
import SelectOneInput from '../../../components/UI/Inputs/SelectOneInput'
import CustomTextInput from '../../../components/UI/Inputs/TextInput/CustomTextInput'
import Button from '../../../components/UI/Buttons/Button/Button'
import TableCheckboxCell from '../../../components/TableCheckboxCell/TableCheckboxCell'
import TableButtonCell from '../../../components/TableButtonCell'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import PaginatedEntityTable from '../../../components/EntityTable/PaginatedEntityTable'
import DetailsAsidePanel from '../../../components/DetailsAsidePanel'
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal'
import AddToStoresModal from '../../../components/AddToStoresModal'
import QuestionTableRow from '../QuestionTableRow'
import QuestionDetails from '../QuestionDetails'
import AddOrganizationQuestionModal from '../AddOrganizationQuestionModal'
import ViewOrganizationQuestionStoresModal from '../../../components/ViewOrganizationQuestionStoresModal'
import {
  Toolbar,
  DashboardInfoWrap,
  ManageEntityDashboardWrap,
  ButtonWrap
} from '../../../global/styles'
import { 
  HeaderButtonWrap,
  EntityDashboardHeadline,
  SectionName,
  AddButton
} from './styles'

const tableColumns = [
  {
    Header: 'Organisation',
    accessor: 'organizationName'
  },
  {
    Header: 'Question',
    accessor: 'question'
  },
  {
    Header: 'Answer',
    accessor: 'answer'
  },
  {
    Header: 'forSearch',
    accessor: 'forSearch'
  }  
]

const ManageOrganizationQuestions = () => {
  const organizationId = useSelector(selectOrganizationId)
  const [activeOrganizationId, setActiveOrganizationId] = useState(null)
  const [activeQuestionId, setActiveQuestionId] = useState(null)
  const [deleteOneId, setDeleteOneId] = useState(null)
  const [showDetails, setShowDetails] = useState(false)
  const [showAddNewModal, setShowAddNewModal] = useState(false)
  const [showAddtoStoreModal, setShowAddtoStoreModal] = useState(false)
  const [showDeleteFromShopModal, setShowDeleteFromShopModal] = useState(false)
  const [showViewShopsModal, setShowViewShopsModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [error, setError] = useState('')
  const [storeError, setStoreError] = useState('')
  const [ getOrganizations, { data: organizationsData } ] = useLazyGetOrganizationsQuery()
  const [ getOrganizationQuestions, { data: questionsData, isLoading, error: errorQuestions } ] = useLazyGetOrganizationQuestionsQuery()
  const [deleteQuestion] = useDeleteOrganizationQuestionMutation()
  const [deleteSelectedIds, { isLoading: deletingItems }] =  usePostDeleteOrganizationQuestionsMutation()
  const [addQuestions] = usePostOrganizationQuestionsToShopsMutation()
  const [deleteQuestions] = useDeleteOrganizationQuestionsToShopsMutation()  
  const [totalPages, setTotalPages] = useState(0)
  const [totalRows, setTotalRows] = useState(0)
  const [search, setSearch] = useState('')

  const columns = useMemo(() => tableColumns, [])

  const data = useMemo(
    () =>
      questionsData 
        ? questionsData?.questions?.map((question) => QuestionTableRow(question)) 
        : [],
    [questionsData]
  )

  const organizationList = useMemo(
    () =>
      organizationsData
        ? organizationsData?.organizations?.map((organization) => ({value: organization.id, label: organization.name })) 
        : [],
    [organizationsData]
  )

  const handleShowShops = (questionId) => {
    setStoreError('')
    setDeleteOneId(questionId)
    setShowViewShopsModal(true)
  }

  const {
    getTableProps,
    getTableBodyProps,
    page,
    headerGroups,
    prepareRow,
    state: { selectedRowIds, pageIndex, pageSize },
    pageOptions,
    toggleAllPageRowsSelected,
    toggleAllRowsSelected,
    gotoPage
  } = useTable(
    {
      columns,
      data,
      getRowId: (row) => row.id,
      initialState: { pageIndex: 0, pageSize: 10 },
      manualPagination: true,
      pageCount: totalPages,
    },
    useGlobalFilter,    
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columnsList) => [
        {
          id: 'selection',
          Cell: TableCheckboxCell
        },
        ...columnsList,
        {
          id: 'deleteIcon',
          // eslint-disable-next-line react/prop-types
          Cell: ({ cell }) => (
            <TableButtonCell
              cell={cell}
              handleShowDetails={handleShowShops}
              handleSetIdToDelete={setDeleteOneId}
              handleShowEditModal={setShowEditModal}
              handleShowConfirmModal={setShowConfirmDelete}
              canEdit
              showView
            />
          )
        }
      ])
    }
  )
  
  const displayError = (err) => {
    setError(getErrorMessage(err))
  }

  const loadQuestions = (orgId) => {
    setError('')
    getOrganizationQuestions({ organizationId: orgId, page: pageIndex, limit: pageSize, search })
      .unwrap()
      .catch((err) => {
        displayError(err)
      })
  }

  const handleChangeSelectedOrganization = (value) => {
    setActiveQuestionId(null)
    setShowDetails(false)
    setActiveOrganizationId(value)
    if (pageIndex === 0) {
      loadQuestions(value)
    } else {
      gotoPage(0)
    } 
  }

  const handleShowDetails = (questionId) => {
    if (questionId !== activeQuestionId) {
      setShowDetails(true)
      setActiveQuestionId(questionId)
    } else {
      setShowDetails(false)
      setActiveQuestionId(null)
    }
  }

  const handleCloseDetails = () => {
    setShowDetails(false)
    setActiveQuestionId(null)
  }

  const handleShowAddNewModal = () => {
    setStoreError('')
    setShowAddNewModal(true)
  }

  const handleCloseAddNewModal = () => {
    setShowAddNewModal(false)
  }

  const handleShowAddToStoreModal = () => {
    setStoreError('')
    setShowAddtoStoreModal(true)
  }

  const handleSubmitAddToStoreModal = (shopIds) => {
    setStoreError('')
    addQuestions({ body: {
      organizationId: activeOrganizationId,
      organizationQuestionIds: keys(selectedRowIds),
      shopIds
    } })
      .unwrap()
      .then(() => {
        setShowAddtoStoreModal(false)
        toggleAllPageRowsSelected(false)
        setStoreError('Not implemented.')
      })
      .catch((err) => {
        setStoreError(getErrorMessage(err))
      })
  }

  const handleCloseAddToStoreModal = () => {
    setShowAddtoStoreModal(false)
  }

  const handleShowDeleteFromShopModal = () => {
    setStoreError('')
    setShowDeleteFromShopModal(true)
  }

  const handleSubmitDeleteFromShopModal = (shopIds) => {
    deleteQuestions({ body: {
      organizationId: activeOrganizationId,
      organizationQuestionIds: keys(selectedRowIds),
      shopIds
    } })
      .unwrap()
      .then(() => {
        // display message
        setShowDeleteFromShopModal(false)
        toggleAllPageRowsSelected(false)
        toggleAllRowsSelected(false)
      })
      .catch((err) => {
        setStoreError(getErrorMessage(err))
      })
  }

  const handleCloseDeleteFromShopModal = () => {
    setShowDeleteFromShopModal(false)
  }

  const handleCloseViewShopsModal = () => {
    setDeleteOneId(null)
    setShowViewShopsModal(false)
  }

  const handleCloseEditModal = () => {
    setShowEditModal(false)
    setDeleteOneId(null)
  }

  const handleCloseConfirmationModal = () => {
    toggleAllPageRowsSelected(false)
    toggleAllRowsSelected(false)
    setShowConfirmDelete(false)
    setDeleteOneId(null)
  }

  const handleDeleteSelected = () => {
    setError('')
    handleCloseDetails()
    setDeleteOneId(null)
    setShowConfirmDelete(false)
    if (deleteOneId) {
      deleteQuestion({ questionId: deleteOneId })
        .unwrap()
        .then(() => {
        })
        .catch((err) => {
          displayError(err);
        })
      return
    }
    if (!isEmpty(selectedRowIds)) {
      deleteSelectedIds({ body: { organizationQuestionIds: keys(selectedRowIds) } })
        .unwrap()
        .then(() => {
        })
        .catch((err) => {
          displayError(err);
        })
    }    
  }

  const handleSearch = () => {
    if (pageIndex === 0) {
      loadQuestions(activeOrganizationId)
    } else {
      gotoPage(0)
    }
  }
  
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }

  useEffect(() => {
    if (organizationId) {
      setActiveOrganizationId(organizationId);
      loadQuestions(organizationId);
    } else {
      getOrganizations()
        .unwrap()
        .then(() => {
        })
        .catch((err) => {
          setError(getErrorMessage(err))
        })      
    }
  }, [])

  useEffect(() => {
    if (questionsData) {
      setTotalPages(questionsData.pagination.pages)
      setTotalRows(questionsData.pagination.total)
      if (pageIndex >= questionsData.pagination.pages) {
        gotoPage(0)
      }
    }
  }, [questionsData])

  useEffect(() => {
    if (organizationsData && organizationsData.organizations.length > 0) {
      setActiveOrganizationId(organizationsData.organizations[0].id);
      loadQuestions(organizationsData.organizations[0].id);
    }
  }, [organizationsData])

  useEffect(() => {
    if (questionsData) {
      loadQuestions(activeOrganizationId)
    }
  }, [pageIndex, pageSize]);

  return (
    <ManageEntityDashboardWrap>
      <DashboardInfoWrap>
        <EntityDashboardHeadline>
          <SectionName variant="headingM" bold>Manage Organisation FAQ</SectionName>
          <HeaderButtonWrap>
            {questionsData && questionsData.questions.length > 0 &&
            <AddButton
              type="button"
              variant={BUTTON_VARIANTS.PRIMARY}
              size={BUTTONS_SIZES.SMALL}
              onClick={() => toggleAllPageRowsSelected(true)}
              isFullWidth={false}
            >
              Select Page
            </AddButton>            
            }            
            {false && questionsData && questionsData.questions.length > 0 &&
              <AddButton
                type="button"
                variant={BUTTON_VARIANTS.PRIMARY}
                size={BUTTONS_SIZES.SMALL}
                onClick={() => toggleAllRowsSelected(true)}
                isFullWidth={false}
              >
                Select All
              </AddButton>
            }
            {questionsData && questionsData.questions.length > 0 &&
              <AddButton
                type="button"
                variant={BUTTON_VARIANTS.PRIMARY}
                size={BUTTONS_SIZES.SMALL}
                onClick={() => toggleAllRowsSelected(false)}
                isFullWidth={false}
              >
                Deselect All
              </AddButton>
            }            
            {questionsData &&
              <AddButton
                type="button"
                variant={BUTTON_VARIANTS.PRIMARY}
                size={BUTTONS_SIZES.SMALL}
                leftIcon={<Add />}
                onClick={handleShowAddNewModal}
                isFullWidth={false}
              >
                Add FAQ
              </AddButton>
            }
            {!(deletingItems || isEmpty(selectedRowIds)) && (
              <>
                <AddButton
                  type="button"
                  variant={BUTTON_VARIANTS.PRIMARY}
                  size={BUTTONS_SIZES.SMALL}
                  leftIcon={<Add />}
                  onClick={handleShowAddToStoreModal}
                  isFullWidth={false}
                >
                  Add To Store
                </AddButton>
                <Button
                  type="button"
                  variant={BUTTON_VARIANTS.PRIMARY}
                  size={BUTTONS_SIZES.SMALL}
                  leftIcon={<DeleteOutline />}
                  onClick={handleShowDeleteFromShopModal}
                  isFullWidth={false}
                  fillColor="#f44336"
                >
                  Delete from Store
                </Button>                
                <Button
                  type="button"
                  variant={BUTTON_VARIANTS.PRIMARY}
                  size={BUTTONS_SIZES.SMALL}
                  leftIcon={<DeleteOutline />}
                  onClick={() => { 
                    setShowConfirmDelete(true) 
                    setError("")
                  }}
                  isFullWidth={false}
                  fillColor="#f44336"
                >
                  Delete Selected
                </Button>
              </>
            )}
          </HeaderButtonWrap>
        </EntityDashboardHeadline>        
        {isLoading && <LoadingSpinner />}
        {errorQuestions && 
          <Toolbar>
            <Typography variant="textS" color="red">Network Error</Typography>
          </Toolbar>          
        }
        {questionsData && (
          <>
            <Toolbar>
              {!organizationId && organizationList && (
                <SelectOneInput
                  options={organizationList}
                  setSelected={handleChangeSelectedOrganization}
                  selected={activeOrganizationId}
                />
              )}
              <CustomTextInput
                handleKeyPress={handleKeyPress}
                handleChange={(e) => {
                  setSearch(e.target.value)
                  // setGlobalFilter(e.target.value)
                }}
                placeholder="Search FAQ by question or answer"
                inputName="search"
                size={INPUT_SIZES.SMALL}
                value={search}
                leftIcon={<Search />}
                isWhite
              />
              <ButtonWrap>
                <Button
                  type="button"
                  variant={BUTTON_VARIANTS.PRIMARY}
                  size={BUTTONS_SIZES.SMALL}
                  onClick={() => handleSearch()}
                >
                  Search
                </Button>
              </ButtonWrap>              
            </Toolbar>
            {error && 
              <Toolbar>
                <Typography variant="textS" color="red">
                {error}
                </Typography>
              </Toolbar>          
            }                     
            <PaginatedEntityTable
              {...{
                getTableProps,
                headerGroups,
                getTableBodyProps,
                page,
                prepareRow,
                rows: data,
                pageOptions,
                pageIndex,
                gotoPage,
                totalRows
              }}
              handleShowDetails={handleShowDetails}
              activeItemId={activeQuestionId}
            />
          </>
        )}
      </DashboardInfoWrap>
      { showDetails &&
      <DetailsAsidePanel isOpen={showDetails} closePanel={handleCloseDetails}>
        <QuestionDetails
          {...{ activeQuestionId }}
          closePanel={handleCloseDetails}
        />
      </DetailsAsidePanel>
      }
      {showConfirmDelete && (
        <DeleteConfirmationModal
          isOpen={showConfirmDelete}
          onClose={handleCloseConfirmationModal}
          entityName={getEntityPropertyByIds(
            selectedRowIds,
            deleteOneId,
            questionsData?.questions,
            'question'
          )}
          entityType="FAQ"
          handleDelete={handleDeleteSelected}
        />
      )}
      {showAddNewModal && 
      <AddOrganizationQuestionModal
        isOpen={showAddNewModal}
        onClose={handleCloseAddNewModal}
        organizationId={activeOrganizationId}
        showOrganization={organizationId == null}
      />
      }      
      {showEditModal && 
      <AddOrganizationQuestionModal
        isOpen={showEditModal}
        onClose={handleCloseEditModal}
        editQuestionId={deleteOneId}
        showOrganization={false}
      />
      }
      {showAddtoStoreModal &&
      <AddToStoresModal
        title="Add FAQ To Stores"
        isOpen={showAddtoStoreModal}
        onSubmit={handleSubmitAddToStoreModal}
        onClose={handleCloseAddToStoreModal}
        organizationId={activeOrganizationId}
        error={storeError}
      />
      }
      {showDeleteFromShopModal &&
      <AddToStoresModal
        title="Delete FAQ From Stores"
        isOpen={showDeleteFromShopModal}
        onSubmit={handleSubmitDeleteFromShopModal}
        onClose={handleCloseDeleteFromShopModal}
        organizationId={activeOrganizationId}
        error={storeError}
      />
      }
      {showViewShopsModal && deleteOneId &&
      <ViewOrganizationQuestionStoresModal
        organizationId={activeOrganizationId}
        questionId={deleteOneId}
        onClose={handleCloseViewShopsModal}
      />
      }      
    </ManageEntityDashboardWrap>
  )
}

export default ManageOrganizationQuestions

ManageOrganizationQuestions.propTypes = {}
