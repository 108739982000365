import React, { useEffect, useState } from 'react'
import { Dashboard, Grade, ShoppingBag } from '@styled-icons/material'
import { withTheme } from 'styled-components'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { round } from 'lodash/math'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash/lang'
import { useLazyGetShopDashboardDetailsQuery } from '../../../api/dashboardApi'
import { getArraySlice } from '../../../utils/helpers'
import ROUTES from '../../../const/routes'
import { BUTTON_VARIANTS, BUTTONS_SIZES } from '../../../const/UIvariants'
import Button from '../../../components/UI/Buttons/Button'
import Typography from '../../../components/UI/Typography'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import ShopPostItem from '../../../components/ShopPostItem'
import Paginator from '../../../components/Paginator'
import SelectOneInput from '../../../components/UI/Inputs/SelectOneInput'
import DateRangeModal from '../../../components/DateRangeModal/DateRangeModal'

import { Footer, HStack } from '../../../global/styles'
import {
  ContentWrap,
  HeadlineWrap,
  IconWrap,
  LatestPosts,
  LatestPostsHeadline,
  ManagePostsLink,
  MetricItem,
  MetricLabel,
  MetricsWrap,
  PostsWrap,
  ButtonWrap
} from './styles'

const TIME_FRAME_OPTIONS = [
  { value: 1, label: '1 Month summary' },
  { value: 3, label: '3 Month summary' },
  { value: 6, label: '6 Month summary' },
  { value: 12, label: '12 Month summary' }
]
const ITEMS_PER_PAGE = 50

const DashboardMainSection = ({ theme, shopId }) => {
  const history = useHistory()
  const [dateRange, setDateRange] = useState({from: "", to: ""})
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedTimeFrame, setSelectedTimeFrame] = useState(1)
  const [pageData, setPageData] = useState([])
  const [dateRangeModalIsOpen, setDateRangeModalIsOpen] = useState(false)
  const [getShopDashboardDetails, { data: dashboardData, isLoading }] = useLazyGetShopDashboardDetailsQuery()

  const onDateRangeSubmit = (values) => {
    setDateRangeModalIsOpen(false)
    setDateRange({from: values.dateFrom, to: values.dateTo})
    getShopDashboardDetails({ shopId, timeFrame: 0, from: dayjs(values.dateFrom).format('YYYY-MM-DD'), to: dayjs(values.dateTo).format('YYYY-MM-DD') })
  }

  useEffect(() => {
    getShopDashboardDetails({ shopId, timeFrame: selectedTimeFrame, from: "", to: "" })
  }, [])

  useEffect(() => {
    getShopDashboardDetails({ shopId, timeFrame: selectedTimeFrame, from: "", to: "" })
  }, [selectedTimeFrame])

  useEffect(() => {
    setPageData(
      getArraySlice(dashboardData?.latestPosts, {
        page: currentPage,
        perPage: ITEMS_PER_PAGE
      })
    )
  }, [currentPage, dashboardData?.latestPosts])

  return (
    <ContentWrap>
      <HeadlineWrap>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <Typography variant="headingL" color={theme.colors.skyNeutral_2}>
              Activity on your Store
            </Typography>
            <ButtonWrap>
              <SelectOneInput
                options={TIME_FRAME_OPTIONS}
                selected={selectedTimeFrame}
                setSelected={setSelectedTimeFrame}
                size="250px"
              />
              <Button
                type="button"
                variant={BUTTON_VARIANTS.PRIMARY}
                size={BUTTONS_SIZES.SMALL}
                onClick={() => setDateRangeModalIsOpen(true)}
              >
               Date Range
              </Button>
            </ButtonWrap>         
          </>
        )}

      </HeadlineWrap>

      {!isEmpty(dashboardData) && (
        <>
        <MetricsWrap>
          <MetricItem>
            <MetricLabel variant="textM" color={theme.colors.yellowNeutral_2}>
              Average review score
            </MetricLabel>
            <HStack justify="flex-start" alignItems="center">
              <IconWrap bgColor={theme.colors.yellowBase3}>
                <Grade size={24} color={theme.colors.yellowBase} />
              </IconWrap>
              <Typography variant="headingXL" color={theme.colors.yellowBase}>
                {round(dashboardData?.reviews?.average, 2)}
              </Typography>
            </HStack>
            <Typography variant="textS" color={theme.colors.yellowNeutral_1}>
              from {dashboardData?.reviews?.total} reviews
            </Typography>
          </MetricItem>
          <MetricItem>
            <MetricLabel variant="textM" color={theme.colors.greenNeutral_2}>
              Products Listed
            </MetricLabel>
            <HStack justify="flex-start" alignItems="center">
              <IconWrap bgColor={theme.colors.green3}>
                <ShoppingBag size={24} color={theme.colors.green_1} />
              </IconWrap>
              <Typography variant="headingXL" color={theme.colors.green_2}>
                {dashboardData?.products}
              </Typography>
            </HStack>
            <Typography variant="textS" color={theme.colors.greenNeutral}>
              Products
            </Typography>
          </MetricItem>
          <MetricItem>
            <MetricLabel variant="textM" color={theme.colors.cyanNeutral_1}>
              Total Posts
            </MetricLabel>
            <HStack justify="flex-start" alignItems="center">
              <IconWrap bgColor={theme.colors.cyanBase3}>
                <Dashboard size={24} color={theme.colors.cyanBase_1} />
              </IconWrap>
              <Typography variant="headingXL" color={theme.colors.cyanBase_1}>
                {dashboardData?.posts}
              </Typography>
            </HStack>
            <Typography variant="textS" color={theme.colors.cyanNeutral}>
              Posts by you
            </Typography>
          </MetricItem>
        </MetricsWrap>
        <MetricsWrap>
          <MetricItem>
            <MetricLabel variant="textM" color={theme.colors.yellowNeutral_2}>
              Profile Views
            </MetricLabel>
            <HStack justify="flex-start" alignItems="center">
              <IconWrap bgColor={theme.colors.yellowBase3}>
                <Grade size={24} color={theme.colors.yellowBase} />
              </IconWrap>
              <Typography variant="headingXL" color={theme.colors.yellowBase}>
                {dashboardData?.profileViews}
              </Typography>
            </HStack>
            <Typography variant="textS" color={theme.colors.yellowNeutral_1}>
              &nbsp;
            </Typography>
          </MetricItem>
          <MetricItem>
            <MetricLabel variant="textM" color={theme.colors.greenNeutral_2}>
              {`Total "Shares"`}
            </MetricLabel>
            <HStack justify="flex-start" alignItems="center">
              <IconWrap bgColor={theme.colors.green3}>
                <ShoppingBag size={24} color={theme.colors.green_1} />
              </IconWrap>
              <Typography variant="headingXL" color={theme.colors.green_2}>
                {dashboardData?.totalShares}
              </Typography>
            </HStack>
            <Typography variant="textS" color={theme.colors.greenNeutral}>
              &nbsp;
            </Typography>
          </MetricItem>
          <MetricItem>
            <MetricLabel variant="textM" color={theme.colors.cyanNeutral_1}>
              {`Total "Go Now" clicks`}
            </MetricLabel>
            <HStack justify="flex-start" alignItems="center">
              <IconWrap bgColor={theme.colors.cyanBase3}>
                <Dashboard size={24} color={theme.colors.cyanBase_1} />
              </IconWrap>
              <Typography variant="headingXL" color={theme.colors.cyanBase_1}>
                {dashboardData?.goToClicks}
              </Typography>
            </HStack>
            <Typography variant="textS" color={theme.colors.cyanNeutral}>
              &nbsp;
            </Typography>
          </MetricItem>
        </MetricsWrap>
        <MetricsWrap>
          <MetricItem>
            <MetricLabel variant="textM" color={theme.colors.yellowNeutral_2}>
              Total Favourite
            </MetricLabel>
            <HStack justify="flex-start" alignItems="center">
              <IconWrap bgColor={theme.colors.yellowBase3}>
                <Grade size={24} color={theme.colors.yellowBase} />
              </IconWrap>
              <Typography variant="headingXL" color={theme.colors.yellowBase}>
                {dashboardData?.totalFavourite}
              </Typography>
            </HStack>
            <Typography variant="textS" color={theme.colors.yellowNeutral_1}>
              &nbsp;
            </Typography>
          </MetricItem>
          <MetricItem>
            <MetricLabel variant="textM" color={theme.colors.greenNeutral_2}>
              Total Subscribers
            </MetricLabel>
            <HStack justify="flex-start" alignItems="center">
              <IconWrap bgColor={theme.colors.green3}>
                <ShoppingBag size={24} color={theme.colors.green_1} />
              </IconWrap>
              <Typography variant="headingXL" color={theme.colors.green_2}>
                {dashboardData?.totalSubscribers}
              </Typography>
            </HStack>
            <Typography variant="textS" color={theme.colors.greenNeutral}>
              Subscribers: {dashboardData?.totalSubscribersActive}
            </Typography>
          </MetricItem>
          <MetricItem>
            <MetricLabel variant="textM" color={theme.colors.yellowNeutral_2}>
              Post Likes
            </MetricLabel>
            <HStack justify="flex-start" alignItems="center">
              <IconWrap bgColor={theme.colors.yellowBase3}>
                <Grade size={24} color={theme.colors.yellowBase} />
              </IconWrap>
              <Typography variant="headingXL" color={theme.colors.yellowBase}>
                {dashboardData?.totalPostLikes}
              </Typography>
            </HStack>
            <Typography variant="textS" color={theme.colors.yellowNeutral_1}>
              &nbsp;
            </Typography>
          </MetricItem>
        </MetricsWrap>
        <MetricsWrap>
          <MetricItem>
            <MetricLabel variant="textM" color={theme.colors.yellowNeutral_2}>
            Total Event Post &quot;Interested&quot;
            </MetricLabel>
            <HStack justify="flex-start" alignItems="center">
              <IconWrap bgColor={theme.colors.yellowBase3}>
                <Grade size={24} color={theme.colors.yellowBase} />
              </IconWrap>
              <Typography variant="headingXL" color={theme.colors.yellowBase}>
                {dashboardData?.totalEventPostInterested}
              </Typography>
            </HStack>
            <Typography variant="textS" color={theme.colors.yellowNeutral_1}>
              All Active &quot;Interested&quot;: {dashboardData?.totalEventPostInterestedActive}
            </Typography>
          </MetricItem>
          <MetricItem>
            <MetricLabel variant="textM" color={theme.colors.yellowNeutral_2}>
              Total Event Post &quot;Got Deal&quot;
            </MetricLabel>
            <HStack justify="flex-start" alignItems="center">
              <IconWrap bgColor={theme.colors.yellowBase3}>
                <Grade size={24} color={theme.colors.yellowBase} />
              </IconWrap>
              <Typography variant="headingXL" color={theme.colors.yellowBase}>
                {dashboardData?.totalEventPostGotDeal}
              </Typography>
            </HStack>
            <Typography variant="textS" color={theme.colors.yellowNeutral_1}>
              All Active &quot;Got Deal&quot;: {dashboardData?.totalEventPostGotDealActive}
            </Typography>
          </MetricItem>
          <MetricItem>
            <MetricLabel variant="textM" color={theme.colors.yellowNeutral_2}>
              Total Event Post &quot;Attended&quot;
            </MetricLabel>
            <HStack justify="flex-start" alignItems="center">
              <IconWrap bgColor={theme.colors.yellowBase3}>
                <Grade size={24} color={theme.colors.yellowBase} />
              </IconWrap>
              <Typography variant="headingXL" color={theme.colors.yellowBase}>
                {dashboardData?.totalEventPostAttended}
              </Typography>
            </HStack>
            <Typography variant="textS" color={theme.colors.yellowNeutral_1}>
              All Active &quot;Attended&quot;: {dashboardData?.totalEventPostAttendedActive}
            </Typography>
          </MetricItem>          
        </MetricsWrap>
        </>
      )}
      {dashboardData && (
        <LatestPosts>
          <DateRangeModal
            isOpen={dateRangeModalIsOpen}
            onClose={() => setDateRangeModalIsOpen(false)}
            onSubmit={onDateRangeSubmit}
            dateFrom={dateRange.from}
            dateTo={dateRange.to}
          />
          <LatestPostsHeadline>
            <Typography variant="textL" color={theme.colors.skyNeutral_2}>
              Latest Posts
            </Typography>
            { false &&
            <ManagePostsLink
              variant="textS"
              color={theme.colors.skyNeutral1}
              onClick={() => {
                history.push(ROUTES.SHOP_PROFILE_POSTS)
              }}
            >
              Manage Posts
            </ManagePostsLink>
            }
          </LatestPostsHeadline>
          <PostsWrap>
            {pageData.map((post) => (
              <ShopPostItem
                key={post.id}
                postId={post.id}
                postImage={post.postImage}
                postDeadline={post.deadline}
                postLikeCount={post.likeCount}
                postInterestedCount={post.interestedCount}
                postAttendedCount={post.attendedCount}
                postDescription={post.description}
                postTitle={post.title}
                dateEnd={post.dateEnd}
                dateStart={post.dateStart}
                showCounts={false}
                showStore
                storeName={post.shopName}
                storeImage={post.shopImage}
                storeBgColor={post.avatarBackground}
                deadlineText={post.deadlineText}
                canEdit={false}
                canDelete={false}
              />
            ))}
          </PostsWrap>
          <Footer>
            <Typography variant="textM" color={theme.colors.skyNeutral2}>
              {`${pageData?.length} from ${dashboardData?.latestPosts?.length} Posts`}
            </Typography>
            <Paginator
              amountOfPages={Math.ceil(
                dashboardData?.latestPosts?.length / ITEMS_PER_PAGE
              )}
              {...{ currentPage, setCurrentPage }}
            />
          </Footer>
        </LatestPosts>
      )}
    </ContentWrap>
  )
}

export default withTheme(DashboardMainSection)

DashboardMainSection.propTypes = {
  theme: PropTypes.object.isRequired,
  shopId: PropTypes.string.isRequired
}
