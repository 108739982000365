import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  useGetManagerQuery,
  usePostDeleteManagersMutation,
  usePatchManagersByUserIdMutation,
  usePostResetUserPasswordByUserIdMutation
} from '../../../api/api.generated'
import { getErrorMessage  } from '../../../utils/helpers'
import {
  BADGE_VARIANTS,
  BUTTON_VARIANTS,
  BUTTONS_SIZES as BUTTON_SIZES,
  USER_AVATAR_SIZES
} from '../../../const/UIvariants'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import EntityPicture from '../../../components/EntityPicture'
import Button from '../../../components/UI/Buttons/Button'
import Badge from '../../../components/UI/Badge'
import ToggleStatusModal from '../../../components/ToggleStatusModal'
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal'
import {
  AsideDetailsDescription,
  AsideDetailsLabel,
  AsideDetailsTitle,
  DetailsInfoBlock,
  DetailsPanelTopSection
} from '../../../global/styles'
import { CustomerDetailsWrap, ButtonWrap } from './styles'

const CustomerDetails = ({ activeCustomerId, closePanel }) => {
  const [deleteError, setDeleteError] = useState("")
  const [statusError, setStatusError] = useState("")
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [showConfirmStatusModal, setShowConfirmStatusModal] = useState(false)
  const [deleteSelectedManager, { isLoading: deletingCustomer }] = usePostDeleteManagersMutation()
  const [resetPassword, { isLoading: resettingPassword }] = usePostResetUserPasswordByUserIdMutation()
  const [changeCustomerStatus, { isLoading: changingStatus }] = usePatchManagersByUserIdMutation()
  const { data: customerData, isLoading } = useGetManagerQuery(activeCustomerId)

  const toggleDeleteModal = () => {
    setDeleteError("");
    setShowConfirmDelete((prev) => !prev)
  }

  const toggleStatusModal = () => {
    setStatusError("");
    setShowConfirmStatusModal((prev) => !prev)
  }

  const handleDeleteUser = () => {
    setDeleteError("");
    deleteSelectedManager({ body: { usersIdsToDelete: [activeCustomerId] } })
      .unwrap()
      .then(() => {
        closePanel()
        setShowConfirmDelete(false)
      })
      .catch((err) => {
        setDeleteError(getErrorMessage(err))
      })        
  }

  const handleResetPassword = () => {
    resetPassword({ userId: activeCustomerId })
  }

  const handleChangeCustomerStatus = () => {
    setStatusError("");
    changeCustomerStatus({
      body: { isActive: !customerData.isActive },
      userId: activeCustomerId
    })
      .unwrap()
      .then(() => {
        toggleStatusModal()
      })
      .catch((err) => {
        setStatusError(getErrorMessage(err))
      })      
  }

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {customerData && (
        <>
          <DetailsPanelTopSection>
            <EntityPicture
              entityName={customerData.fullName}
              size={USER_AVATAR_SIZES.LARGE}
              image={customerData.userAvatar}
              bgColor={customerData.avatarBackground}
            />
            <AsideDetailsTitle align="center">
              {customerData.fullName}
            </AsideDetailsTitle>
            <AsideDetailsLabel align="center">
              {customerData.email}
            </AsideDetailsLabel>
            <AsideDetailsLabel align="center">
              {customerData.phoneNumber === null ? '' : '+' }{customerData.phoneNumber}
            </AsideDetailsLabel>
          </DetailsPanelTopSection>
          <CustomerDetailsWrap>
            <AsideDetailsTitle>Manager Details</AsideDetailsTitle>
            {false &&
            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">Password</AsideDetailsLabel>
              <Button
                isLoading={resettingPassword}
                // isFullWidth
                size={BUTTON_SIZES.SMALL}
                onClick={handleResetPassword}
                variant={BUTTON_VARIANTS.SECONDARY}
              >
                Reset Password
              </Button>
            </DetailsInfoBlock>
            }

            <DetailsInfoBlock>
              <AsideDetailsLabel align="left">Status</AsideDetailsLabel>
              <AsideDetailsDescription>
                <Badge
                  label={customerData.isActive ? 'Active' : 'Inactive'}
                  variant={
                    customerData.isActive
                      ? BADGE_VARIANTS.GREEN
                      : BADGE_VARIANTS.LIGHT
                  }
                />
              </AsideDetailsDescription>
            </DetailsInfoBlock>
            <ButtonWrap>
              <Button
                variant={BUTTON_VARIANTS.SECONDARY}
                size={BUTTON_SIZES.MED}
                onClick={toggleDeleteModal}
                isLoading={changingStatus || deletingCustomer}
              >
                Delete
              </Button>
              <Button
                variant={
                  customerData.isActive
                    ? BUTTON_VARIANTS.OUTLINED
                    : BUTTON_VARIANTS.PRIMARY
                }
                size={BUTTON_SIZES.MED}
                onClick={toggleStatusModal}
                isLoading={changingStatus || deletingCustomer}
              >
                {customerData.isActive ? 'Deactivate' : 'Activate'}
              </Button>
            </ButtonWrap>
          </CustomerDetailsWrap>
          { showConfirmStatusModal &&
          <ToggleStatusModal
            entityType="Manager"
            isCurrentlyActive={customerData.isActive}
            handleToggleStatus={handleChangeCustomerStatus}
            onClose={toggleStatusModal}
            isOpen={showConfirmStatusModal}
            entityName={customerData.fullName}
            error={statusError}
          />
          }
          { showConfirmDelete &&
          <DeleteConfirmationModal
            isOpen={showConfirmDelete}
            onClose={toggleDeleteModal}
            entityName={customerData.fullName}
            entityType="Manager"
            handleDelete={handleDeleteUser}
            error={deleteError}
          />
          }
        </>
      )}
    </>
  )
}

export default CustomerDetails

CustomerDetails.defaultProps = {
  activeCustomerId: null
}

CustomerDetails.propTypes = {
  activeCustomerId: PropTypes.string,
  closePanel: PropTypes.func.isRequired
}
