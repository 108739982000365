import React, { useEffect, useMemo, useState } from 'react'
import { Calculate, LocalOffer } from '@styled-icons/material'
import { usePagination, useRowSelect, useTable } from 'react-table'
import { isEmpty } from 'lodash/lang'
import { keys } from 'lodash/object'
import {
  useGetTagsQuery,
  usePostDeleteTagsMutation,
  usePostTagMutation
} from '../../../api/api.generated'
import TableCheckboxCell from '../../../components/TableCheckboxCell/TableCheckboxCell'
import TableDeleteCell from '../../../components/TableDeleteCell'
import {
  DashboardInfoWrap,
  ManageEntityDashboardWrap
} from '../../../global/styles'
import DashboardHeader from '../../../components/DashboardHeader'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import { MetricsItem } from '../../customers/ManageCustomers/styles'
import MetricItem from '../../../components/MetricItem/MetricItem'
import EntityTable from '../../../components/EntityTable/EntityTable'
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal'
import { getEntityPropertyByIds } from '../../../utils/helpers'
import TagsTableRow from '../TagsTableRow'
import AddTagModal from '../AddTagModal'

const tableColumns = [
  {
    Header: 'Category label',
    accessor: 'tagLabel'
  },
  {
    Header: 'Created',
    accessor: 'created'
  },
  {
    Header: 'Stores Related',
    accessor: 'shopsRelated'
  },
  {
    Header: 'Products Related',
    accessor: 'productsRelated'
  }
]
const ManageTags = () => {
  const [deleteOneId, setDeleteOneId] = useState(null)
  const [showAddNewModal, setShowAddNewModal] = useState(false)
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [metrics, setMetrics] = useState([])
  const { data: tagsData, isLoading } = useGetTagsQuery()
  const [deleteSelectedIds, { isLoading: deletingItems }] =
    usePostDeleteTagsMutation()
  const [addNewTag, { isLoading: addingNewTag }] = usePostTagMutation()

  const getMetrics = (data) => [
    {
      description: '',
      icon: <LocalOffer />,
      iconBgColor: 'green3',
      isActive: false,
      metricName: 'Store Categories',
      numberAndIconColor: 'greenBase',
      onClick: () => {},
      value: data?.shopTags
    },
    {
      description: '',
      icon: <LocalOffer />,
      iconBgColor: 'skyNeutral3',
      isActive: false,
      metricName: 'Product Categories',
      numberAndIconColor: 'skyNeutral_1',
      onClick: () => {},
      value: data?.productTags
    },
    {
      description: '',
      icon: <Calculate />,
      iconBgColor: 'skyBase3',
      isActive: false,
      metricName: 'Total Categories',
      numberAndIconColor: 'skyBase',
      onClick: () => {},
      value: data?.tagsCount
    }
  ]

  const data = useMemo(
    () => (tagsData ? tagsData?.tags?.map((tag) => TagsTableRow(tag)) : []),
    [tagsData]
  )
  const columns = useMemo(() => tableColumns, [])

  const {
    getTableProps,
    getTableBodyProps,
    rows,
    page,
    headerGroups,
    prepareRow,
    state: { selectedRowIds, pageIndex },
    pageOptions,
    toggleAllPageRowsSelected,
    toggleAllRowsSelected,
    gotoPage
  } = useTable(
    {
      columns,
      data,
      getRowId: (row) => row.tagId,
      initialState: { pageIndex: 0, pageSize: 10 }
    },
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columnsList) => [
        {
          id: 'selection',
          Cell: TableCheckboxCell
        },
        ...columnsList,
        {
          id: 'deleteIcon',
          // eslint-disable-next-line react/prop-types
          Cell: ({ cell }) => (
            <TableDeleteCell
              cell={cell}
              handleShowConfirmModal={setShowConfirmDelete}
              handleSetIdToDelete={setDeleteOneId}
            />
          )
        }
      ])
    }
  )

  const handleCloseConfirmationModal = () => {
    toggleAllPageRowsSelected(false)
    toggleAllRowsSelected(false)
    setShowConfirmDelete(false)
    setDeleteOneId(null)
  }

  const handleDeleteSelected = () => {
    if (deleteOneId) {
      setDeleteOneId(null)
      deleteSelectedIds({ body: { tagsIdsToDelete: [deleteOneId] } })
      handleCloseConfirmationModal()
      return
    }
    if (!isEmpty(selectedRowIds)) {
      setDeleteOneId(null)
      deleteSelectedIds({ body: { tagsIdsToDelete: keys(selectedRowIds) } })
      handleCloseConfirmationModal()
    }
  }

  const handleToggleAddNewModal = () => {
    setShowAddNewModal((p) => !p)
  }

  const handleCreateNewTag = (values) => {
    addNewTag({
      body: {
        tagIcon: values.icon,
        backgroundColor: values.color,
        tagName: values.tagName
      }
    })
      .unwrap()
      .then(() => {
        handleToggleAddNewModal()
      })
  }

  useEffect(() => {
    if (tagsData) {
      setMetrics(getMetrics(tagsData))
    }
  }, [tagsData])

  return (
    <ManageEntityDashboardWrap>
      <DashboardInfoWrap>
        <DashboardHeader
          headline="Manage Categories"
          handleDelete={() => {
            setShowConfirmDelete(true)
          }}
          disableDelete={deletingItems || isEmpty(selectedRowIds)}
          addNewLabel="Add Category"
          handleAddNew={handleToggleAddNewModal}
        />
        {isLoading && <LoadingSpinner />}
        {tagsData && (
          <>
            <MetricsItem>
              {metrics.map((metric) => (
                <MetricItem
                  key={metric.metricName}
                  description={metric.description}
                  icon={metric.icon}
                  iconBgColor={metric.iconBgColor}
                  id={metric.metricName}
                  isActive={metric.isActive}
                  metricName={metric.metricName}
                  numberAndIconColor={metric.numberAndIconColor}
                  onClick={() => {}}
                  value={metric.value}
                />
              ))}
            </MetricsItem>
            <EntityTable
              {...{
                getTableProps,
                headerGroups,
                getTableBodyProps,
                page,
                prepareRow,
                rows,
                pageOptions,
                pageIndex,
                gotoPage
              }}
              handleShowDetails={() => {}}
              activeItemId={null}
            />
          </>
        )}
      </DashboardInfoWrap>

      <AddTagModal
        isOpen={showAddNewModal}
        {...{
          handleCloseAddNewModal: handleToggleAddNewModal,
          handleCreateNewTag,
          addingNewTag
        }}
      />
      {tagsData && (!isEmpty(selectedRowIds) || deleteOneId) && (
        <DeleteConfirmationModal
          isOpen={showConfirmDelete}
          onClose={handleCloseConfirmationModal}
          entityName={getEntityPropertyByIds(
            selectedRowIds,
            deleteOneId,
            tagsData?.tags,
            'tagName'
          )}
          entityType="Category"
          handleDelete={handleDeleteSelected}
        />
      )}
    </ManageEntityDashboardWrap>
  )
}

export default ManageTags

ManageTags.propTypes = {}
