import React, { useState, useMemo } from 'react'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import { withTheme } from 'styled-components'
import { Formik, Form } from 'formik'
import { isNull } from 'lodash/lang'
import * as Yup from 'yup'
import { 
  useGetOrganizationsQuery,
  usePostShopMutation,
  useGetHereTokenQuery
} from '../../../api/api.generated'
import TextInput from '../../../components/UI/Inputs/TextInput'
import Typography from '../../../components/UI/Typography'
import PhoneNumberInput from '../../../components/UI/Inputs/PhoneNumberInput'
import SelectAddressInput from '../../../components/UI/Inputs/SelectAddressInput'
import {
  BUTTON_VARIANTS,
  BUTTONS_SIZES,
  INPUT_SIZES
} from '../../../const/UIvariants'
import FormSelectOneInput from '../../../components/UI/Inputs/SelectOneInput/FormSelectOneInput'
import Button from '../../../components/UI/Buttons/Button/Button'
import {
  ButtonWrap,
  Headline,
  ModalBody,
  ErrorWrap,
  InputRow
} from './styles'

const emptyShop = {
  name: '',
  email: '',
  phoneNumber: '',
  address: '',
  geoData: {},
  organization: ''
}

const AddShopModal = ({
  isOpen,
  onClose,
  organizationId
}) => {
  const [error, setError] = useState('')
  const [addShop, { isAdding }] = usePostShopMutation()

  let validationSchema
  if (isNull(organizationId)) {
    validationSchema = Yup.object({
      name: Yup.string()
        .matches(
          /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/,
          'Please enter valid name'
        )
        .max(40, 'Name is too long')
        .required('Name is required'),
      email: Yup.string().max(255, 'Email is too long').email('Invalid email address'),
      phoneNumber: Yup.string().max(50, 'Phone numeber is too long').matches(/^[0-9]+$/, 'Please enter valid phone number'),
      address: Yup.string().max(255, 'Too long, max 255 characters').required('Store Address is required'),
      organization: Yup.string().required('Organization is required')
    })
  } else {
    validationSchema = Yup.object({
      name: Yup.string()
        .matches(
          /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/,
          'Please enter valid name'
        )
        .max(40, 'Name is too long')
        .required('Name is required'),
      email: Yup.string().max(255, 'Email is too long').email('Invalid email address'),
      phoneNumber: Yup.string().max(50, 'Phone numeber is too long').matches(/^[0-9]+$/, 'Please enter valid phone number'),
      address: Yup.string().max(255, 'Too long, max 255 characters').required('Store Address is required')
    })
  }

  // useGetHereTokenQuery()

  let organizationSelect
  if (isNull(organizationId)) {
    const { data: organizationsData } = useGetOrganizationsQuery();
    const organizationList = useMemo(
      () =>
        organizationsData
          ? organizationsData?.organizations?.map((organization) => ({value: organization.id, label: organization.name })) 
          : [],
      [organizationsData]
    )

    organizationSelect = 
      <InputRow>
        <FormSelectOneInput
          inputName="organization"
          label="Organisation"
          options={organizationList}
          size="100%"
          value=""
          isGrey
        />            
      </InputRow>
  }

  const displayError = (err) => {
    if (err instanceof Error) {
      setError(err.message)
    } else if (err instanceof Object) {
      let message = '';
      Object.keys(err).forEach((key) => {
        message += `${err[key]} `;
      });
      setError(message)
    } else {
      setError('Network error')
    }    
  }

  const handleSubmit = (values) => {
    setError('')

    addShop({
      body: {
        name: values.name,
        email: values.email,
        phone: values.phoneNumber,
        address: values.address,
        geoData: values.geoData,
        organization: isNull(organizationId) ? values.organization : organizationId
      }
    })
    .unwrap()
    .then(() => {
      onClose()
    })
    .catch((err) => {
      displayError(err);
    })
  }

  return (
    <Modal {...{ isOpen }}>
      <ModalBody>
        <Formik
          initialValues={emptyShop}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <Headline>Add Store</Headline>
            <InputRow>
              <TextInput
                label="Name"
                placeholder="Enter name"
                inputName="name"
                size={INPUT_SIZES.SMALL}
              />
            </InputRow>
            <InputRow>
              <TextInput
                label="Email Address"
                placeholder="Email Address"
                inputName="email"
                size={INPUT_SIZES.SMALL}
              />
              <PhoneNumberInput
                label="Phone Number"
                placeholder="Phone Number"
                inputName="phoneNumber"
                type="tel"
                size={INPUT_SIZES.SMALL}
              />
            </InputRow>
            <SelectAddressInput inputName="address" />
            { isNull(organizationId) &&
              organizationSelect
            }
            {error && 
              <ErrorWrap>
                <Typography variant="textS" color="red">
                {error}
                </Typography>
              </ErrorWrap>          
            }
            <ButtonWrap>
              <Button
                variant={BUTTON_VARIANTS.PRIMARY}
                size={BUTTONS_SIZES.MED}
                type="submit"
                isLoading={isAdding}
              >
                Save
              </Button>
              <Button
                variant={BUTTON_VARIANTS.SECONDARY}
                size={BUTTONS_SIZES.MED}
                onClick={onClose}
                type="button"
              >
                Cancel
              </Button>
            </ButtonWrap>
          </Form>
        </Formik>
      </ModalBody>
    </Modal>
  )
}

export default withTheme(AddShopModal)

AddShopModal.defaultProps = {
  organizationId: null
}

AddShopModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  organizationId: PropTypes.string
}
