import React from 'react'
import { useField, useFormikContext } from 'formik'
import PropTypes from 'prop-types'
import { InputWrapper, StyledSelect } from './styles'
import hereWegoApi from '../../../../api/hereWegoApi'
import { InputError, InputOuterLabel } from '../TextInput/styles'

const suggestionsToOptionsAdapter = (suggestions) =>
  
  suggestions
    ? suggestions?.map((item) => ({
        value: item.title,
        label: item.title
      }))
    : [{ value: '', label: '' }]

const SelectAddressInput = ({ inputName }) => {
  const [field, meta, helpers] = useField({ name: inputName })

  const { setFieldValue } = useFormikContext()

  const [trigger, { locationSuggestionsOptions }] =
    hereWegoApi?.endpoints?.getAddressSuggestions?.useLazyQuery({
      selectFromResult: ({ data }) => ({
        locationSuggestionsOptions: data?.items
      })
    })

  const handleSearchAddress = (searchQuery) => {
    if (searchQuery.length > 3) {
      trigger({ query: searchQuery })
    }
  }

  const handleSelectFromResults = (selected) => {
    console.log("selected",selected);
    helpers.setValue(selected.value)
    setFieldValue(
      'geoData',
      locationSuggestionsOptions?.find((elem) => elem.title === selected.value)
    )
  }
  
  return (
    <InputWrapper>
      <InputOuterLabel htmlFor={field.name} variant="textS" component="label">
        Address
      </InputOuterLabel>
      <StyledSelect
        id={field.name}
        classNamePrefix="rs"
        isSearchable
        placeholder="Start typing to select address"
        name={field.name}
        filterOption={null}
        onInputChange={handleSearchAddress}
        onChange={handleSelectFromResults}
        defaultInputValue={field.value}
        options={suggestionsToOptionsAdapter(locationSuggestionsOptions)}
      />
      <InputError>{meta.error ? meta.error : null}</InputError>
    </InputWrapper>
  )
}

export default SelectAddressInput

SelectAddressInput.propTypes = {
  inputName: PropTypes.string.isRequired
}
