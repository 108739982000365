import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useGlobalFilter, usePagination, useRowSelect, useTable } from 'react-table'
import { useSelector } from 'react-redux'
import { Search, Dashboard, InsertInvitation, Update } from '@styled-icons/material'
import { isEmpty } from 'lodash/lang'
import { keys } from 'lodash/object'
import {
  selectOrganizationId
} from '../../../app/selectors'
import {
  useLazyGetOrganizationsQuery
} from '../../../api/api.generated'
import {
  usePostDeletePostsMutation,
  useLazyGetOrganizationShopPostsQuery
} from '../../../api/shopPostsApi'
import TableCheckboxCell from '../../../components/TableCheckboxCell/TableCheckboxCell'
import TableButtonCell from '../../../components/TableButtonCell'
import {
  DashboardInfoWrap,
  ManageEntityDashboardWrap,
  Toolbar,
  ButtonWrap
} from '../../../global/styles'
import Button from '../../../components/UI/Buttons/Button'
import Typography from '../../../components/UI/Typography'
import DashboardHeader from '../../../components/DashboardHeader'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import { MetricsItem } from '../../customers/ManageCustomers/styles'
import MetricItem from '../../../components/MetricItem/MetricItem'
import PaginatedEntityTable from '../../../components/EntityTable/PaginatedEntityTable'
import DetailsAsidePanel from '../../../components/DetailsAsidePanel'
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal'
import CustomTextInput from '../../../components/UI/Inputs/TextInput/CustomTextInput'
import SelectOneInput from '../../../components/UI/Inputs/SelectOneInput'
import { BUTTON_VARIANTS, BUTTONS_SIZES, INPUT_SIZES } from '../../../const/UIvariants'
import { getEntityPropertyByIds, getErrorMessage } from '../../../utils/helpers'
import AddNewPostModal from '../../shop/AddNewPostModal/AddNewPostModal'
import PostTableRow from '../PostTableRow'
import PostDetails from '../PostDetails'

const tableColumns = [
  {
    Header: 'Post Title',
    accessor: 'postTitle'
  },
  {
    Header: 'Store',
    accessor: 'shop'
  },
  {
    Header: 'Description',
    accessor: 'description'
  },
  {
    Header: 'Event',
    accessor: 'deadline'
  },
  {
    Header: 'Code',
    accessor: 'code'
  },
  {
    Header: 'Organisation',
    accessor: 'isOrganizationPost'
  },
  {
    Header: 'Like',
    accessor: 'likeCount'
  },
  {
    Header: 'Interested',
    accessor: 'interestedCount'
  },
  {
    Header: 'Attended/Got Deal',
    accessor: 'attendedCount'
  },
  {
    Header: 'forSearch',
    accessor: 'forSearch'
  }  
]

const ManagePosts = () => {
  const organizationId = useSelector(selectOrganizationId)
  const [metrics, setMetrics] = useState([])
  const [activeOrganizationId, setActiveOrganizationId] = useState(null)
  const [activePostId, setActivePostId] = useState(null)
  const [deleteOneId, setDeleteOneId] = useState(null)
  const [error, setError] = useState('')
  const [deleteError, setDeleteError] = useState('')
  const [showDetails, setShowDetails] = useState(false)
  const [showAddNewModal, setShowAddNewModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [ getOrganizations, { data: organizationsData } ] = useLazyGetOrganizationsQuery()
  const [getPosts, { data: postsData, isLoading }] = useLazyGetOrganizationShopPostsQuery()
  const [deleteSelectedIds, { isLoading: deletingItems }] = usePostDeletePostsMutation()
  const [totalPages, setTotalPages] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [search, setSearch] = useState('');

  const getMetrics = useCallback(
    (data) => [
      {
        description: '',
        icon: <Dashboard />,
        iconBgColor: 'green3',
        isActive: false,
        metricName: 'Total Posts',
        numberAndIconColor: 'greenBase',
        onClick: () => {},
        value: data?.postCount
      },
      {
        description: '',
        icon: <Update />,
        iconBgColor: 'skyNeutral3',
        isActive: false,
        metricName: 'Non-Event Posts',
        numberAndIconColor: 'skyNeutral_1',
        onClick: () => {},
        value: data?.postWithoutDeadline
      },
      {
        description: '',
        icon: <InsertInvitation />,
        iconBgColor: 'pinkBase3',
        isActive: false,
        metricName: 'Event Posts',
        numberAndIconColor: 'pinkBase',
        onClick: () => {},
        value: data?.postWithDeadline
      }
    ],
    []
  )

  const columns = useMemo(() => tableColumns, [])

  const data = useMemo(
    () =>
      postsData ? postsData?.posts?.map((post) => PostTableRow(post)) : [],
    [postsData]
  )

  const organizationList = useMemo(
    () =>
      organizationsData
        ? organizationsData?.organizations?.map((organization) => ({value: organization.id, label: organization.name })) 
        : [],
    [organizationsData]
  )

  const {
    getTableProps,
    getTableBodyProps,
    page,
    headerGroups,
    prepareRow,
    state: { selectedRowIds, pageIndex, pageSize },
    pageOptions,
    toggleAllPageRowsSelected,
    toggleAllRowsSelected,
    gotoPage
  } = useTable(
    {
      columns,
      data,
      getRowId: (row) => row.postId,
      initialState: { pageIndex: 0, pageSize: 10 },
      manualPagination: true,
      pageCount: totalPages,
    },
    useGlobalFilter,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columnsList) => [
        {
          id: 'selection',
          Cell: TableCheckboxCell
        },
        ...columnsList,
        {
          id: 'deleteIcon',
          // eslint-disable-next-line react/prop-types
          Cell: ({ cell }) => (
            <TableButtonCell
              cell={cell}
              handleSetIdToDelete={setDeleteOneId}
              handleShowEditModal={setShowEditModal}
              handleShowConfirmModal={setShowConfirmDelete}
              canEdit
              checkCanEdit
            />
          )
        }
      ])
    }
  )

  const loadPosts = (orgId) => {
    setError('')
    getPosts({ organizationId: orgId, page: pageIndex, limit: pageSize, search })
      .unwrap()
      .catch((err) => {
        setError(getErrorMessage(err))
      })
  }

  const handleChangeSelectedOrganization = (value) => {
    setActivePostId(null)
    setShowDetails(false)
    setActiveOrganizationId(value)
    if (pageIndex === 0) {
      loadPosts(value)
    } else {
      gotoPage(0)
    }
  }

  const handleShowPostDetails = (postId) => {
    if (postId !== activePostId) {
      setShowDetails(true)
      setActivePostId(postId)
    } else {
      setShowDetails(false)
      setActivePostId(null)
    }
  }

  const handleCloseDetails = () => {
    setShowDetails(false)
    setActivePostId(null)
  }

  const handleShowAddNewModal = () => {
    setError("");
    setShowAddNewModal(true)
  }

  const handleCloseAddNewModal = () => {
    setShowAddNewModal(false)
  }

  const handleCloseEditModal = () => {
    setError("");
    setShowEditModal(false)
    setDeleteOneId(null)
  }

  const handleCloseConfirmationModal = () => {
    toggleAllPageRowsSelected(false)
    toggleAllRowsSelected(false)
    setShowConfirmDelete(false)
    setDeleteOneId(null)
  }

  const deleteSelectedPosts = (ids) => {
    deleteSelectedIds({ body: { postsIdsToDelete: ids } })
      .unwrap()
      .then(() => {
        handleCloseConfirmationModal()
      })
      .catch((err) => {
        setDeleteError(getErrorMessage(err))
      })          
  }

  const handleDeleteSelected = () => {
    setError("");
    setDeleteError("");
    if (deleteOneId) {
      deleteSelectedPosts([deleteOneId])
      return
    }
    if (!isEmpty(selectedRowIds)) {
      deleteSelectedPosts(keys(selectedRowIds))
    }
  }

  const handleSearch = () => {
    if (pageIndex === 0) {
      loadPosts(activeOrganizationId)
    } else {
      gotoPage(0)
    }
  }
  
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }

  useEffect(() => {
    if (organizationId) {
      setActiveOrganizationId(organizationId);
      loadPosts(organizationId)
    } else {
      getOrganizations()
        .unwrap()
        .then(() => {
        })
        .catch((err) => {
          setError(getErrorMessage(err))
        })
    }
  }, [])

  useEffect(() => {
    if (postsData) {
      setTotalPages(postsData.pagination.pages)
      setTotalRows(postsData.pagination.total)
      if (pageIndex >= postsData.pagination.pages) {
        gotoPage(0)
      }
    }
  }, [postsData])

  useEffect(() => {
    if (organizationsData && organizationsData.organizations.length > 0) {
      setActiveOrganizationId(organizationsData.organizations[0].id);
      loadPosts(organizationsData.organizations[0].id)
    }
  }, [organizationsData])

  useEffect(() => {
    if (postsData) {
      loadPosts(activeOrganizationId)
    }
  }, [pageIndex, pageSize]);

  useEffect(() => {
    if (postsData) {
      setMetrics(getMetrics(postsData))
    }
  }, [postsData])  

  return (
    <ManageEntityDashboardWrap>
      <DashboardInfoWrap>
        <DashboardHeader
          headline="Manage Posts"
          handleDelete={() => {
            setShowConfirmDelete(true)
          }}
          disableDelete={deletingItems || isEmpty(selectedRowIds)}
          addNewLabel="Add Post"
          showAddButton={organizationsData && organizationsData.organizations.length > 0}
          handleAddNew={handleShowAddNewModal}
          showSelectPageButton={postsData && postsData.posts.length > 0}
          handleSelectPage={() => toggleAllPageRowsSelected(true)}
          handleSelectAll={() => toggleAllRowsSelected(true)}
          handleDeselectAll={() => toggleAllRowsSelected(false)}
          showSelectButton={postsData && postsData.posts.length > 0}
        />
        {isLoading && <LoadingSpinner />}
        {error && 
          <Toolbar>
            <Typography variant="textS" color="red">{error}</Typography>
          </Toolbar>
        }        
        {postsData && (
          <>
            <MetricsItem>
              {metrics.map((metric) => (
                <MetricItem
                  key={metric.metricName}
                  description={metric.description}
                  icon={metric.icon}
                  iconBgColor={metric.iconBgColor}
                  id={metric.metricName}
                  isActive={metric.isActive}
                  metricName={metric.metricName}
                  numberAndIconColor={metric.numberAndIconColor}
                  onClick={() => {}}
                  value={metric.value}
                />
              ))}
            </MetricsItem>
            <Toolbar>
              {!organizationId && organizationList && (
                <SelectOneInput
                  options={organizationList}
                  setSelected={handleChangeSelectedOrganization}
                  selected={activeOrganizationId}
                />
              )}
              <CustomTextInput
                handleKeyPress={handleKeyPress}
                handleChange={(e) => {
                  setSearch(e.target.value)
                  // setGlobalFilter(e.target.value)
                }}
                placeholder="Search posts by title or description"
                inputName="search"
                size={INPUT_SIZES.SMALL}
                value={search}
                leftIcon={<Search />}
                isWhite
              />
              <ButtonWrap>
                <Button
                  type="button"
                  variant={BUTTON_VARIANTS.PRIMARY}
                  size={BUTTONS_SIZES.SMALL}
                  onClick={() => handleSearch()}
                >
                  Search
                </Button>
              </ButtonWrap>              
            </Toolbar>
            <PaginatedEntityTable
              {...{
                getTableProps,
                headerGroups,
                getTableBodyProps,
                page,
                prepareRow,
                rows: data,
                pageOptions,
                pageIndex,
                gotoPage,
                totalRows
              }}
              handleShowDetails={handleShowPostDetails}
              activeItemId={activePostId}
            />
          </>
        )}
      </DashboardInfoWrap>
      { showDetails &&
      <DetailsAsidePanel isOpen={showDetails} closePanel={handleCloseDetails}>
        <PostDetails
          {...{ activePostId }}
          closePanel={handleCloseConfirmationModal}
        />
      </DetailsAsidePanel>
      }
      {showAddNewModal &&
      <AddNewPostModal
        isOpen={showAddNewModal}
        onClose={handleCloseAddNewModal}
        showShops
        organizationId={activeOrganizationId}
      />
      }  
      {showEditModal && 
      <AddNewPostModal
        isOpen={showEditModal}
        onClose={handleCloseEditModal}
        showShops={false}
        editPostId={deleteOneId}
      />
      }            
      {showConfirmDelete && (
        <DeleteConfirmationModal
          isOpen={showConfirmDelete}
          onClose={handleCloseConfirmationModal}
          entityName={getEntityPropertyByIds(
            selectedRowIds,
            deleteOneId,
            postsData?.posts,
            'postTitle'
          )}
          entityType="Post"
          handleDelete={handleDeleteSelected}
          error={deleteError}
        />
      )}
    </ManageEntityDashboardWrap>
  )
}

export default ManagePosts

ManagePosts.propTypes = {}
