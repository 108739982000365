import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useGlobalFilter, usePagination, useRowSelect, useTable } from 'react-table'
import dayjs from 'dayjs'
import { Search, Flag, Unpublished, Store } from '@styled-icons/material'
import {
  selectOrganizationId
} from '../../../app/selectors'
import {
  useLazyGetOrganizationsQuery,
  useLazyGetDonationsQuery
} from '../../../api/api.generated'
import { getErrorMessage } from '../../../utils/helpers'
import { BUTTON_VARIANTS, BUTTONS_SIZES, INPUT_SIZES } from '../../../const/UIvariants'
import Typography from '../../../components/UI/Typography'
import SelectOneInput from '../../../components/UI/Inputs/SelectOneInput'
import CustomTextInput from '../../../components/UI/Inputs/TextInput/CustomTextInput'
import TableButtonCell from '../../../components/TableButtonCell'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import PaginatedEntityTable from '../../../components/EntityTable/PaginatedEntityTable'
import DetailsAsidePanel from '../../../components/DetailsAsidePanel'
import Button from '../../../components/UI/Buttons/Button'
import MetricItem from '../../../components/MetricItem'
import DateRangeModal from '../../../components/DateRangeModal/DateRangeModal'
import DonationTableRow from '../DonationTableRow'
import DonationDetails from '../DonationDetails'
import {
  Toolbar,
  DashboardInfoWrap,
  ManageEntityDashboardWrap,
  ButtonWrap
} from '../../../global/styles'
import { 
  EntityDashboardHeadline,
  SectionName,
  MetricsWrap,
  DateButtonWrap
} from './styles'

const tableColumns = [
  {
    Header: 'Date',
    accessor: 'date'
  },
  {
    Header: 'Store',
    accessor: 'shop'
  },
  {
    Header: 'User',
    accessor: 'user'
  },
  {
    Header: 'Amount',
    accessor: 'amount'
  },
  {
    Header: 'Note',
    accessor: 'note'
  },
  {
    Header: 'forSearch',
    accessor: 'forSearch'
  }  
]

const adaptMetrics = (data) => [
  {
    total: `$${data.metrics.total}`,
    metricName: 'Total',
    icon: <Store />,
    description: '',
    iconBgColor: 'skyNeutral3',
    numberAndIconColor: 'skyNeutral_1'
  },
  {
    total: `$${data.metrics.withdrawn}`,
    metricName: 'Withdrawn',
    icon: <Unpublished />,
    description: '',
    iconBgColor: 'green3',
    numberAndIconColor: 'green_1'
  },
  {
    total: `$${data.metrics.balance}`,
    metricName: 'Balance',
    icon: <Flag />,
    description: `Next Withdraw: ${dayjs(data?.nextWithdrawal).format('DD MMMM YYYY')}`,
    iconBgColor: 'pinkBase3',
    numberAndIconColor: 'pinkBase'
  }
]

const TIME_FRAME_OPTIONS = [
  { value: 1, label: '1 Month summary' },
  { value: 3, label: '3 Month summary' },
  { value: 6, label: '6 Month summary' },
  { value: 12, label: '12 Month summary' }
]

const ManageDonations = () => {
  const [metricsData, setMetricsData] = useState([])
  const organizationId = useSelector(selectOrganizationId)
  const [activeDonationId, setActiveDonationId] = useState(null)
  const [error, setError] = useState('')
  const [showDetails, setShowDetails] = useState(false)
  const [dateRangeModalIsOpen, setDateRangeModalIsOpen] = useState(false)
  const [ getOrganizations, { data: organizationsData } ] = useLazyGetOrganizationsQuery()
  const [ getDonations, { data: donationsData, isLoading } ] = useLazyGetDonationsQuery()
  const [activeOrganizationId, setActiveOrganizationId] = useState(null)
  const [selectedTimeFrame, setSelectedTimeFrame] = useState(1)
  const [dateRange, setDateRange] = useState({from: "", to: ""})
  const [search, setSearch] = useState('')
  const [totalPages, setTotalPages] = useState(0)
  const [totalRows, setTotalRows] = useState(0)

  const columns = useMemo(() => tableColumns, [])

  const data = useMemo(
    () =>
    donationsData 
        ? donationsData?.donations?.map((donation) => DonationTableRow(donation)) 
        : [],
    [donationsData]
  )

  const organizationList = useMemo(
    () =>
      organizationsData
        ? organizationsData?.organizations?.map((organization) => ({value: organization.id, label: organization.name })) 
        : [],
    [organizationsData]
  )

  const {
    getTableProps,
    getTableBodyProps,
    page,
    headerGroups,
    prepareRow,
    state: { pageIndex, pageSize },
    pageOptions,
    gotoPage
  } = useTable(
    {
      columns,
      data,
      getRowId: (row) => row.id,
      initialState: { pageIndex: 0, pageSize: 10 },
      manualPagination: true,
      pageCount: totalPages,
    },
    useGlobalFilter,    
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columnsList) => [
        {
          id: 'first',
        },
        ...columnsList,
        {
          id: 'deleteIcon',
          // eslint-disable-next-line react/prop-types
          Cell: ({ cell }) => (
            <TableButtonCell
              cell={cell}
              showDelete={false}
            />
          )
        },
        {
          id: 'last',
        }
      ])
    }
  )
  
  const handleShowDetails = (donationId) => {
    if (donationId !== activeDonationId) {
      setActiveDonationId(donationId)
      setShowDetails(true)
    } else {
      setShowDetails(false)
      setActiveDonationId(null)
    }
  }

  const handleCloseDetails = () => {
    setShowDetails(false)
    setActiveDonationId(null)
  }

  const loadDonations = () => {
    let timeFrame = selectedTimeFrame
    if (dateRange.from !== "") {
      timeFrame = 0
    }
    setError('')
    getDonations({ organizationId: activeOrganizationId, page: pageIndex, limit: pageSize, search, timeFrame, from: dateRange.from, to: dateRange.to })
      .unwrap()
      .catch((err) => {
        setError(getErrorMessage(err))
      })
  }

  const handleChangeSelectedOrganization = (value) => {
    setActiveDonationId(null)
    setShowDetails(false)
    setActiveOrganizationId(value)
  }

  const onTimeframeChange = (value) => {
    setSelectedTimeFrame(value)
    setDateRange({from: "", to: ""})
  }

  const onDateRangeSubmit = (values) => {
    setDateRangeModalIsOpen(false)
    setDateRange({from: dayjs(values.dateFrom).format('YYYY-MM-DD'), to: dayjs(values.dateTo).format('YYYY-MM-DD')})
  }

  const handleSearch = () => {
    if (pageIndex === 0) {
      loadDonations()
    } else {
      gotoPage(0)
    }
  }
  
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }

  useEffect(() => {
    if (organizationId) {
      setActiveOrganizationId(organizationId);
    } else {
      getOrganizations()
        .unwrap()
        .then(() => {
        })
        .catch((err) => {
          setError(getErrorMessage(err))
        })      
    }
  }, [])

  useEffect(() => {
    if (donationsData) {
      setTotalPages(donationsData.pagination.pages)
      setTotalRows(donationsData.pagination.total)
      if (pageIndex >= donationsData.pagination.pages) {
        gotoPage(0)
      }
    }
  }, [donationsData])

  useEffect(() => {
    if (organizationsData && organizationsData.organizations.length > 0) {
      setActiveOrganizationId(organizationsData.organizations[0].id);
    }
  }, [organizationsData])

  useEffect(() => {
    if (activeOrganizationId) {
      handleSearch()
    }
  }, [activeOrganizationId, selectedTimeFrame, dateRange])

  useEffect(() => {
    if (donationsData) {
      loadDonations()
    }
  }, [pageIndex, pageSize]);

  useEffect(() => {
    if (donationsData) {
      setMetricsData(adaptMetrics(donationsData))
    }
  }, [donationsData])

  return (
    <ManageEntityDashboardWrap>
      <DashboardInfoWrap>
        <EntityDashboardHeadline>
          <SectionName variant="headingM" bold>Manage Donations</SectionName>
        </EntityDashboardHeadline>        
        <MetricsWrap>
          {metricsData.map((metricItem) => (
            <MetricItem
              description={metricItem.description}
              icon={metricItem.icon}
              iconBgColor={metricItem.iconBgColor}
              key={metricItem.metricName}
              isActive={false}
              metricName={metricItem.metricName}
              numberAndIconColor={metricItem.numberAndIconColor}
              onClick={() => {}}
              value={metricItem.total}
            />
          ))}
        </MetricsWrap>        
        {isLoading && <LoadingSpinner />}
        {error && 
          <Toolbar>
            <Typography variant="textS" color="red">{error}</Typography>
          </Toolbar>
        }         
        {donationsData && (
          <>
            <Toolbar>
              {!organizationId && organizationList && (
                <SelectOneInput
                  options={organizationList}
                  setSelected={handleChangeSelectedOrganization}
                  selected={activeOrganizationId}
                />
              )}
              <DateButtonWrap>
                <SelectOneInput
                  options={TIME_FRAME_OPTIONS}
                  selected={selectedTimeFrame}
                  setSelected={onTimeframeChange}
                  size="250px"
                />
                <Button
                  type="button"
                  variant={BUTTON_VARIANTS.PRIMARY}
                  size={BUTTONS_SIZES.SMALL}
                  onClick={() => setDateRangeModalIsOpen(true)}
                >
                Date Range
                </Button>
              </DateButtonWrap>
              <CustomTextInput
                handleKeyPress={handleKeyPress}
                handleChange={(e) => {
                  setSearch(e.target.value)
                  // setGlobalFilter(e.target.value)
                }}
                placeholder="Search donation by note"
                inputName="search"
                size={INPUT_SIZES.SMALL}
                value={search}
                leftIcon={<Search />}
                isWhite
              />
              <ButtonWrap>
                <Button
                  type="button"
                  variant={BUTTON_VARIANTS.PRIMARY}
                  size={BUTTONS_SIZES.SMALL}
                  onClick={() => handleSearch()}
                >
                  Search
                </Button>
              </ButtonWrap>
            </Toolbar>
            <PaginatedEntityTable
              {...{
                getTableProps,
                headerGroups,
                getTableBodyProps,
                page,
                prepareRow,
                pageOptions,
                pageIndex,
                gotoPage,
                totalRows
              }}
              handleShowDetails={handleShowDetails}
              activeItemId={activeDonationId}
            />
            <DateRangeModal
              isOpen={dateRangeModalIsOpen}
              onClose={() => setDateRangeModalIsOpen(false)}
              onSubmit={onDateRangeSubmit}
              dateFrom={dateRange.from}
              dateTo={dateRange.to}
            />            
          </>
        )}
      </DashboardInfoWrap>
      { showDetails &&
      <DetailsAsidePanel isOpen={showDetails} closePanel={handleCloseDetails}>
        <DonationDetails
          activeDonationId={activeDonationId}
          closePanel={handleCloseDetails}
        />
      </DetailsAsidePanel>
      }
    </ManageEntityDashboardWrap>
  )
}

export default ManageDonations

ManageDonations.propTypes = {}
